<template>
    <div>
        <div class="vertical-nav-header d-flex items-center pt-0 ">
            <v-img :src="store.state.configuracionGeneral.logo_empresa_2"
                  max-width="100px" alt="logo" contain eager class="app-logo me-3"></v-img> 
        </div>
        <h4 class="app-title white--text">
            {{ FuncionesGenerales.camelCase(store.state.configuracionGeneral.nombre_empresa) }} </h4>
        <small class="m-0 p-0">{{ store.state.configuracionGeneral.rif_empresa }}</small>
        <br>
        
    </div>
</template>
<script>
import store from '@/store';
import FuncionesGenerales from '@/funciones/funciones'
export default {
    setup() {
        return {
            store, 
            FuncionesGenerales
        }
    },
}
</script>