<template>
  <v-select
    :prepend-icon="icon ? icons.mdiGroup : null"
    :disabled="disabled"
    v-model="datoselect"
    :items="datos"
    label="Grupo administracion"
    item-value="id"
    hide-details="auto"
    item-text="descripcion"
    :dense="outlined"
    :outlined="outlined"
    :loading="cargando"
  >
  </v-select>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import PuestoService from '@/api/servicios/PuestoService'
import { mdiGroup } from '@mdi/js'
export default {
  props: {
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    todos: Boolean,
    SelectDefault: Number,
    icon: Boolean,
  },
  setup(props, contex) {
    const datos = ref([])
    const datoselect = ref(null)
    const cargando = ref(false)
    onBeforeMount(async () => {
      await cargarDatos()
    })

    const cargarDatos = async () => {
      datos.value = []
      if (props.todos == true) {
        datos.value.push({ id: -1, descripcion: 'TODOS' })
      }
      cargando.value = true
      PuestoService.obtenergrupoNegocio()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = datos.value.concat(response.data.datos)
          }
          if (props.SelectDefault != undefined) {
            datoselect.value = props.SelectDefault
            contex.emit(
              'getSelect',
              datos.value.find(item => item.id == datoselect.value),
            )
          }
          console.log(response.data)
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargardatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    watch(datoselect, () => {
      contex.emit(
        'getSelect',
        datos.value.find(item => item.id == datoselect.value),
      )
    })

    return {
      icons: {
        mdiGroup,
      },
      datos,
      datoselect,
      cargando,
    }
  },
}
</script>