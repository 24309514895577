<template>
  <div>
    <p class="text-2xl mb-6">Locatarios cuenta global</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <select-grupo-negocio
              :SelectDefault="-1"
              :todos="true"
              @getSelect="getGrupoNegocio"
              :dense="true"
              :outlined="true"
            ></select-grupo-negocio>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="getVer"
          :acciones="['VER']"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
    </v-card>
    <modal-detalles ref="ModalDetalleRef"></modal-detalles>
  </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue'
import { ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import store from '@/store'
import PuestoService from '@/api/servicios/PuestoService'
import ModalDetalles from './componentes/ModalDetalles.vue'
// demos

export default {
  components: { TablaReporte, SelectGrupoNegocio, ModalDetalles },
  setup() {
    const nombreReporte = ref(`Locatarios cuenta global`)
    const grupoNegocio = ref({})
    const cabecera = ref([
      { text: 'Locatario', value: 'nombre' },
      { text: 'Venta total', value: 'monto_total' },
      { text: '% Administracion', value: 'monto_admin' },
      { text: 'Total Locatario', value: 'monto_puesto' },
      { text: 'Fechas pendientes', value: 'fechas_pendientes', format: { small: true } },
      { text: 'Ultimo Pago', value: 'ultmo_fecha_pago' },
    ])
    const ModalDetalleRef = ref(null)
    const datos = ref([])
    const refModalComanda = ref()
    const cargando = ref(false)
    const validar = () => {
      let validado = true
      if (grupoNegocio.value.id == undefined) {
        validado = false
      }
      return validado
    }

    const buscarDatos = () => {
      cargando.value = true

      var parametros = {
        id_grupo_negocio: grupoNegocio.value.id,
      }
      PuestoService.ReportePuestoCuentaGlobal(parametros)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Locatarios cuenta global`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error', 
              error: response,
              funcion: 'buscarDatos',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
              message: 'error' + err,
              type: 'error', 
              error: {
                        ...err, 
                        response: error?.response?.data
                    },
              funcion: 'buscarDatos',
            })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const getVer = dato => {
      console.log(dato)
      ModalDetalleRef.value.abrirModal(dato)
    }

    const getGrupoNegocio = dato => {
      grupoNegocio.value = dato
    }

    return {
      cabecera,
      datos,
      validar,
      buscarDatos,
      cargando,
      nombreReporte,
      getGrupoNegocio,
      getVer,
      refModalComanda,
      ModalDetalleRef,
    }
  },
}
</script>
  