<template>

    <v-dialog v-model="dialog" width="1000px"  >
        <v-card>
            
            <v-card-text>
              
              
                        <div>
                            <span class="m-0 pt-6"> Locatario: {{ datos.nombre }} </span>
                            <br>
                        </div>
                   
             
                <v-divider></v-divider>
                <v-row class="  mb-4">
                    <v-col>

                        <div>
                            <v-simple-table dense fixed-header height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                #
                                            </th>
                                            <th class="text-left">
                                                Fecha
                                            </th>
                                            <th class="text-right">
                                                Venta total
                                            </th>
                                            <th class="text-right">
                                                % Administracion
                                            </th>
                                            <th class="text-right">
                                                Total locatario
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in datos.detalles " :key="index">
                                            <td>{{ item.id_cierre }}</td>
                                            <td>{{ item.fecha }}</td>
                                            <td class="text-right">{{ item.monto_total.toFixed(2)  }}</td>
                                            <td class="text-right">{{ item.monto_admin.toFixed(2)  }}</td>
                                            <td class="text-right">{{ item.monto.toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { ref } from '@vue/composition-api' 
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import { mdiEye } from '@mdi/js'
export default {
    components: {
        CabeceraEmpresa
    },
    setup(props) {
        const dialog = ref(false)
        const datos = ref({})
        const abrirModal = (datosIn) => {
  
            dialog.value = true
            datos.value = datosIn
        }

        return {
            dialog,
            datos,
            icons: {
                mdiEye
            },
            abrirModal
        }
    },
}
</script>